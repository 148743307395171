import React, {useLayoutEffect, useState, useEffect, useRef} from 'react';
import {Container} from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';

import constants from '../constants.js';

import './HomeScreen.css';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function HomePage(props) {
  const [windowWidth, windowHeight] = useWindowSize();
  const imagePathRef = useRef();

  useEffect(() => {
    props.updateDrawerButton(null, ''); // TODO Replace with below
    // props.updateDrawerButton(<MenuIcon />, 'View recently added');

    // Set image path based on group
    switch (process.env.REACT_APP_GROUP_KEY) {
      case constants.GROUP_KEYS.NIGHTSHADOWS:
        imagePathRef.current = '/img/nsls/NSLS.jpg';
        break;
      case constants.GROUP_KEYS.SUPERFUNTIMES:
        imagePathRef.current = '/img/yay/panda.gif';
        break;
      case constants.GROUP_KEYS.HORSHAM:
        // TODO
        break;
      default:
        imagePathRef.current = '';
    }
  }, []);

  return (<>
    <Container className='the-HomeScreen-Container' disableGutters={true}
      style={{height: windowHeight - constants.APPBAR_HEIGHT, width: windowWidth}}
    >
      <img className='the-HomeScreen-MainImage' alt='' src={imagePathRef.current} />
    </Container>
  </>);
}

export default HomePage;
