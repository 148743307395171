import {React} from 'react';
import {IconButton, Divider, Drawer} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './DrawerMenu.css';

function DrawerMenu(props) {
  const closeDrawer = () => {
    props.toggleDrawer(false);
  };

  return (
    <Drawer
      classes={{paper: 'the-DrawerMenu-Paper'}}
      anchor='right'
      open={props.showDrawer}
      onClose={closeDrawer}
    >
      <div className='the-DrawerMenu-Header'>
        <IconButton onClick={closeDrawer}>
          <ChevronRightIcon />
        </IconButton>
        <span>{props.title}</span>
      </div>
      <Divider />
      <div className='the-DrawerMenu-Body'>
        {props.children}
      </div>
    </Drawer>
  );
}

export default DrawerMenu;
