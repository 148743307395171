import {React, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Badge, Container, Card, CardActionArea, Divider, FormControlLabel, Switch, Button} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';

import constants from '../constants.js';

import './MomentList.css';
import DrawerMenu from './DrawerMenu.js';
import DrawerMenuInputGroup from './DrawerMenuInputGroup.js';

function MomentList(props) {
  const [moments, setMoments] = useState(props.moments);
  const [selectedGameKey, setSelectedGameKey] = useState('all');
  const [selectedSortDirection, setSelectedSortDirection] = useState(constants.SORT_DIRECTIONS.OLDEST_FIRST);
  const [rememberSettings, setRememberSettings] = useState(false);

  // Update moments
  useEffect(() => {
    if (!props.moments) { // This can happen on startup
      return;
    }

    let newMoments;

    // Filter for selected game
    if (selectedGameKey === 'all') {
      newMoments = [...props.moments];
    } else {
      newMoments = props.moments.filter(moment => moment.game_key === selectedGameKey);
    }

    // Sort if necessary (default is oldest first)
    if (selectedSortDirection === constants.SORT_DIRECTIONS.NEWEST_FIRST) {
      newMoments.reverse();
    }

    setMoments(newMoments);
  }, [props.moments, selectedGameKey, selectedSortDirection]);

  useEffect(() => {
    // Show a badge when filter settings are other than the defaults
    props.updateDrawerButton(
      <Badge
        color='secondary'
        variant='dot'
        invisible={selectedGameKey === 'all' && selectedSortDirection === constants.SORT_DIRECTIONS.OLDEST_FIRST}
      >
        <FilterListIcon />
      </Badge>,
      'Filter/sort screenshots'
    );
  }, [selectedGameKey, selectedSortDirection]);

  useEffect(() => {
    // Restore filter selections from cookies
    const previousRememberSettings = Cookies.get(constants.COOKIES.MOMENTS_REMEMBER);
    if (previousRememberSettings === 'true') {
      setRememberSettings(true);
      const previousGameKey = Cookies.get(constants.COOKIES.MOMENTS_GAME);
      const previousSortDirection = Cookies.get(constants.COOKIES.MOMENTS_SORT);
      if (previousGameKey) setSelectedGameKey(previousGameKey);
      if (previousSortDirection) setSelectedSortDirection(previousSortDirection);
    }
  }, []);

  const handleGameSelect = (gameKey) => {
    setSelectedGameKey(gameKey);
    Cookies.set(constants.COOKIES.MOMENTS_GAME, gameKey, {expires: 365, sameSite: 'strict'});
  };

  const handleSortSelect = (sortDirection) => {
    setSelectedSortDirection(sortDirection);
    Cookies.set(constants.COOKIES.MOMENTS_SORT, sortDirection, {expires: 365, sameSite: 'strict'});
  };

  const toggleRememberSettings = (shouldRemember) => {
    if (typeof shouldRemember !== 'boolean') {
      shouldRemember = !rememberSettings;
    }
    setRememberSettings(shouldRemember);
    Cookies.set(constants.COOKIES.MOMENTS_REMEMBER, shouldRemember, {expires: 365, sameSite: 'strict'});
  };

  const resetToDefaults = () => {
    handleGameSelect('all');
    handleSortSelect(constants.SORT_DIRECTIONS.OLDEST_FIRST);
  };

  const sortedGameKeys = props.momentCounts ? Object.keys(props.momentCounts).sort() : [];

  return (<>
    <DrawerMenu
      title='Filter/Sort Moments'
      showDrawer={props.showDrawer}
      toggleDrawer={props.toggleDrawer}
    >
      {/* Game options */}
      {props.games && props.momentCounts &&
        <DrawerMenuInputGroup
          type='radio'
          label='Game'
          selectedValue={selectedGameKey}
          setSelectedValue={handleGameSelect}
          allOption='true'
          allTitle='All the games!'
          allCount={sortedGameKeys.reduce((sum, key) => sum + props.momentCounts[key], 0)}
          values={sortedGameKeys}
          labels={sortedGameKeys.map(gameKey => props.games[gameKey].short_name)}
          titles={sortedGameKeys.map(gameKey => props.games[gameKey].long_name)}
          counts={sortedGameKeys.map(gameKey => props.momentCounts[gameKey])}
        />
      }

      {/* Sort options */}
      {selectedSortDirection &&
        <DrawerMenuInputGroup
          type='radio'
          label='Sort'
          selectedValue={selectedSortDirection}
          setSelectedValue={handleSortSelect}
          values={[constants.SORT_DIRECTIONS.OLDEST_FIRST, constants.SORT_DIRECTIONS.NEWEST_FIRST]}
          labels={['Oldest first', 'Newest first']}
          titles={['Oldies first', 'Newbies first']}
        />
      }

      <Divider />

      {/* Remember switch */}
      <FormControlLabel control={
        <Switch checked={rememberSettings} onChange={toggleRememberSettings} />
      } label='Remember settings' />

      {/* Reset button */}
      <Button variant='outlined' color='secondary' onClick={resetToDefaults}>Reset to defaults</Button>
    </DrawerMenu>

    <Container className='the-MomentList-Container' maxWidth='xl' disableGutters={false}>
      {moments && moments.map(moment => (
        <Card className='the-MomentList-Card' variant='outlined' key={moment.id}>
          <CardActionArea title={moment.title} component={Link} to={constants.PAGE_PATHS.MOMENTS + '/' + moment.id}>
            {moment.title}
          </CardActionArea>
        </Card>
      ))}
    </Container>
  </>);
}

export default MomentList;
