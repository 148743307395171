import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

import './index.css';

import constants from './constants.js';
import registerServiceWorker from './registerServiceWorker';

import App from './components/App';

// Use a create-react-app environment variable to set the group (e.g. NightShadows) globally at build time
// Note: Must be one of the constants.GROUP_KEYS
const group = process.env.REACT_APP_GROUP_KEY;

let theme;
switch (group) {
  case constants.GROUP_KEYS.NIGHTSHADOWS:
    document.title = "NightShadows Linkshell";
    theme = createMuiTheme({
      palette: {
        type: 'dark',
        primary: {main: '#065e28'},
        secondary: {main: '#ffab00'},
      },
    });
    break;
  case constants.GROUP_KEYS.SUPERFUNTIMES:
    document.title = "SuperFunTimes!";
    theme = createMuiTheme({
      palette: {
        type: 'dark',
        primary: {main: purple[700], contrastText: '#eceff1'},
        secondary: green,
      },
    });
    break;
  case constants.GROUP_KEYS.HORSHAM:
    // TODO
    break;
  default:
    console.error("That's not a valid group!");
}

ReactDOM.render((
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </MuiThemeProvider>
), document.getElementById('root'));
registerServiceWorker();
