import {React, useState, useEffect} from 'react';
import {Route, Link, withRouter} from 'react-router-dom';
import {CssBaseline, AppBar, Toolbar, IconButton, Tabs, Tab} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import HomeIcon from '@material-ui/icons/Home';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import 'dejavu-sans/css/dejavu-sans.css';

import constants from '../constants.js';

import './App.css';
import HomeScreen from './HomeScreen';
import ScreenshotViewer from './ScreenshotViewer';
//import StoryViewer from './StoryViewer';
import MomentViewer from './MomentViewer';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: fade(theme.palette.primary.dark, 0.5), // Set alpha transparency to 50%
    '& a': {
      textShadow: '#333 0 0 5px',
      '&$selected': {
        textShadow: theme.palette.secondary.dark + ' 0 0 5px',
      },
    }
  },
  selected: {}, // Used as variable above (see https://material-ui.com/customization/theming/#custom-variables)
}));

function App(props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerIcon, setDrawerIcon] = useState();
  const [drawerIconTitle, setDrawerIconTitle] = useState();
  const [games, setGames] = useState();
  const [screenshots, setScreenshots] = useState();
  const [screenshotCounts, setScreenshotCounts] = useState();
  const [screenshotTags, setScreenshotTags] = useState();
  const [moments, setMoments] = useState();
  const [momentCounts, setMomentCounts] = useState();

  const theme = useTheme();
  const classes = useStyles(theme);
  const isSmallScreen = useMediaQuery('(max-width: 445px)');
  const tutorialSeen = Cookies.get(constants.COOKIES.TUTORIAL_SEEN);

  useEffect(() => {
    // Set selected tab based on URL path
    let initialTabIndex;
    if (window.location.pathname.startsWith(constants.PAGE_PATHS.SCREENSHOTS)) {
      initialTabIndex = 1;
    // } else if (window.location.pathname.startsWith(constants.PAGE_PATHS.STORIES)) {
    //   initialTabIndex = 2;
    } else if (window.location.pathname.startsWith(constants.PAGE_PATHS.MOMENTS)) {
      initialTabIndex = 2;
    } else {
      initialTabIndex = 0;
    }
    setSelectedTabIndex(initialTabIndex);
  }, [props.location]); // props.location comes from withRouter

  useEffect(() => {
    // Use Fetch API to get games
    fetch(constants.DATA_URLS.GAMES)
      .then(response => response.json())
      .then(jsonResponse => {
        setGames(jsonResponse.reduce((jsonGames, currentGame) => {
          // Convert to map from data key to game object
          // TODO Don't hard-code column/field names (elsewhere too)
          jsonGames[currentGame.game_key] = currentGame;
          return jsonGames;
        }, {}));
      });

    // Use Fetch API to get screenshots
    fetch(constants.DATA_URLS.SCREENSHOTS)
      .then(response => response.json())
      .then(jsonResponse => {
        const newScreenshotCounts = {}; // A map of game key to number of screenshots for it
        const newScreenshotTags = {}; // A map of game key to a Set of all tags found on its screenshots

        // Remove screenshots with the wrong group key
        // TODO Don't even request these (need to update DB interface)
        jsonResponse = jsonResponse.filter(jsonEntry => jsonEntry.group_key === process.env.REACT_APP_GROUP_KEY);

        // TODO Error handling (redirect to fun error page)
        for (const jsonEntry of jsonResponse) {
          const fullPath = process.env.PUBLIC_URL + '/img/' + process.env.REACT_APP_GROUP_KEY + '/ss/' + jsonEntry.game_key + '/' + jsonEntry.path;
          jsonEntry.source = {
            regular: fullPath,
            thumbnail: fullPath.replace(/\.\w+$/, '_tn.png'), // Replace the file extension with '_tn.png'
          };

          // Save off metadata for filtering
          if (newScreenshotCounts[jsonEntry.game_key] === undefined) {
            newScreenshotCounts[jsonEntry.game_key] = 1;
          } else {
            newScreenshotCounts[jsonEntry.game_key]++;
          }

          if (jsonEntry.tags) {
            if (newScreenshotTags['all'] === undefined) {
              newScreenshotTags['all'] = new Set(jsonEntry.tags);
            } else {
              jsonEntry.tags.forEach(tag => newScreenshotTags['all'].add(tag));
            }

            if (newScreenshotTags[jsonEntry.game_key] === undefined) {
              newScreenshotTags[jsonEntry.game_key] = new Set(jsonEntry.tags);
            } else {
              jsonEntry.tags.forEach(tag => newScreenshotTags[jsonEntry.game_key].add(tag));
            }
          }
        }

        setScreenshots(jsonResponse);
        setScreenshotCounts(newScreenshotCounts);
        setScreenshotTags(newScreenshotTags);
      });

    // Use Fetch API to get moments
    fetch(constants.DATA_URLS.MOMENTS)
      .then(response => response.json())
      .then(jsonResponse => {
        const newMomentCounts = {}; // A map of game key to number of moments for it

        // Remove moments with the wrong group key
        // TODO Don't even request these (need to update DB interface)
        jsonResponse = jsonResponse.filter(jsonEntry => jsonEntry.group_key === process.env.REACT_APP_GROUP_KEY);

        // TODO Error handling (redirect to fun error page)
        for (const jsonEntry of jsonResponse) {
          const newPathsAndCaptions = [];
          for (const pathAndCaption of jsonEntry.paths_and_captions) {
            const newPathAndCaption = {};
            if (pathAndCaption[0]) {
              if (pathAndCaption[0].includes("youtube")) { // Assume all videos are YouTube embed URLs
                newPathAndCaption.source = pathAndCaption[0];
              } else {
                newPathAndCaption.source = process.env.PUBLIC_URL + '/img/' +
                  process.env.REACT_APP_GROUP_KEY + '/mm/' + jsonEntry.game_key + '/' + pathAndCaption[0];
              }
            }
            if (pathAndCaption[1]) {
              newPathAndCaption.caption = pathAndCaption[1];
            }
            newPathsAndCaptions.push(newPathAndCaption);
          }
          jsonEntry.paths_and_captions = newPathsAndCaptions;

          // Save off metadata for filtering
          if (newMomentCounts[jsonEntry.game_key] === undefined) {
            newMomentCounts[jsonEntry.game_key] = 1;
          } else {
            newMomentCounts[jsonEntry.game_key]++;
          }
        }

        setMoments(jsonResponse);
        setMomentCounts(newMomentCounts);
      });
  }, []);

  // Handler for onChange on the Tabs component
  const handleTabChange = (event, newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  // Set whether the drawer should be shown, toggling if shouldShow is not provided
  const toggleDrawer = (shouldShow) => {
    if (typeof shouldShow === 'boolean') {
      setShowDrawer(shouldShow);
    } else {
      setShowDrawer(!showDrawer);
    }

    // Don't show tutorial anymore
    if (!tutorialSeen) {
      Cookies.set(constants.COOKIES.TUTORIAL_SEEN, 'true', {expires: 365}); // 1 year
    }
  };

  // Set the icon and title of the drawer button (called from children)
  const updateDrawerButton = (newDrawerIcon, newDrawerIconTitle) => {
    setDrawerIcon(newDrawerIcon);
    setDrawerIconTitle(newDrawerIconTitle);
  };

  return (<>
    <CssBaseline />
    <div className='the-App-BackgroundContainer'>
      <div className='the-App-Background' />
    </div>
    <div className='the-App-Root'>
      <AppBar className={'the-App-AppBar ' + classes.appBar} position='static'>
        <Toolbar className='the-App-AppBar-Toolbar' disableGutters>
          <Tabs className='the-App-AppBar-Tabs' value={selectedTabIndex} onChange={handleTabChange}>
            <Tab classes={{selected: classes.selected}} title='Homepage'
              label={isSmallScreen ? null : 'HOME'} icon={isSmallScreen ? <HomeIcon /> : null}
              component={Link} to={constants.PAGE_PATHS.HOME} />
            <Tab classes={{selected: classes.selected}} title='Screenshots and Videos'
              label={isSmallScreen ? null : 'Screenshots'} icon={isSmallScreen ? <PhotoCameraIcon /> : null}
              component={Link} to={constants.PAGE_PATHS.SCREENSHOTS} />
            {/*
            {process.env.REACT_APP_GROUP_KEY === constants.GROUP_KEYS.NIGHTSHADOWS &&
              <Tab classes={{selected: classes.selected}}
                label='Stories' component={Link} to={constants.PAGE_PATHS.STORIES} />
            }
            */}
            <Tab classes={{selected: classes.selected}} title='Memorable Moments'
              label={isSmallScreen ? null : 'Moments'} icon={isSmallScreen ? <PhotoAlbumIcon /> : null}
              component={Link} to={constants.PAGE_PATHS.MOMENTS} />
          </Tabs>
          {drawerIcon &&
            <IconButton edge='end' onClick={toggleDrawer}
              className={'the-App-AppBar-MenuButton' + (!tutorialSeen ? ' the-tutorial' : '')}
              title={drawerIconTitle}
            >
              {drawerIcon}
            </IconButton>
          }
        </Toolbar>
      </AppBar>

      <main className='the-App-Content'>
        <Route exact path={constants.PAGE_PATHS.HOME}
          render={routeProps => (
            <HomeScreen {...routeProps}
              updateDrawerButton={updateDrawerButton}
              toggleDrawer={toggleDrawer}
              showDrawer={showDrawer}
            />
          )}
        />
        <Route path={constants.PAGE_PATHS.SCREENSHOTS}
          render={routeProps => (
            <ScreenshotViewer {...routeProps}
              updateDrawerButton={updateDrawerButton}
              toggleDrawer={toggleDrawer}
              showDrawer={showDrawer}
              screenshots={screenshots}
              screenshotCounts={screenshotCounts}
              screenshotTags={screenshotTags}
              games={games}
            />
          )}
        />
        {/*
        {process.env.REACT_APP_GROUP_KEY === constants.GROUP_KEYS.NIGHTSHADOWS &&
          <Route path={constants.PAGE_PATHS.STORIES}
            render={routeProps => (
              <StoryViewer {...routeProps}
                updateDrawerButton={updateDrawerButton}
                toggleDrawer={toggleDrawer}
                showDrawer={showDrawer}
              />
            )}
          />
        }
        */}
        <Route path={constants.PAGE_PATHS.MOMENTS}
          render={routeProps => (
            <MomentViewer {...routeProps}
              updateDrawerButton={updateDrawerButton}
              toggleDrawer={toggleDrawer}
              showDrawer={showDrawer}
              moments={moments}
              momentCounts={momentCounts}
              games={games}
            />
          )}
        />
      </main>
    </div>
  </>);
}

export default withRouter(App);
