import {React, useEffect, useState, useRef} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {WindowScroller, AutoSizer, Masonry, CellMeasurerCache, CellMeasurer, createMasonryCellPositioner} from 'react-virtualized';
import {Badge, Zoom, Fab, Divider, FormControlLabel, Switch, Button} from '@material-ui/core';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cookies from 'js-cookie';

import constants from '../constants.js';

import './ScreenshotViewer.css';
import DrawerMenu from './DrawerMenu.js';
import DrawerMenuInputGroup from './DrawerMenuInputGroup.js';

const TARGET_COLUMN_WIDTH = 300; // px
let columnCount = Math.floor(window.innerWidth / TARGET_COLUMN_WIDTH);
let columnWidth = Math.floor(window.innerWidth / columnCount);
const cellMeasurerCache = new CellMeasurerCache({
  defaultHeight: 150,
  defaultWidth: columnWidth,
  fixedWidth: true,
});
const cellPositioner = createMasonryCellPositioner({
  cellMeasurerCache,
  columnCount,
  columnWidth,
  spacer: 0,
});

function ScreenshotViewer(props) {
  const [screenshots, setScreenshots] = useState([]);
  const [selectedGameKey, setSelectedGameKey] = useState('all');
  const [selectedSortDirection, setSelectedSortDirection] = useState(constants.SORT_DIRECTIONS.OLDEST_FIRST);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagCounts, setTagCounts] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [rememberSettings, setRememberSettings] = useState(false);

  // Update screenshots
  useEffect(() => {
    resetMasonry();
    if (!props.screenshots) { // This can happen on startup
      return;
    }

    let newScreenshots;

    // Filter for selected game and tags
    if (selectedGameKey === 'all') {
      if (selectedTags.length === 0) {
        newScreenshots = [...props.screenshots];
      } else {
        newScreenshots = props.screenshots.filter(shot => selectedTags.every(tag => shot.tags.includes(tag)));
      }
    } else {
      if (selectedTags.length === 0) {
        newScreenshots = props.screenshots.filter(shot => shot.game_key === selectedGameKey);
      } else {
        newScreenshots = props.screenshots.filter(shot => {
          return shot.game_key === selectedGameKey
            && selectedTags.every(tag => shot.tags && shot.tags.includes(tag));
        });
      }
    }

    // Update tag counts
    const newTagCounts = {}; // A map of tag to number of screenshots tagged with it
    newScreenshots.forEach(shot => {
      if (shot.tags) {
        shot.tags.forEach(tag => {
          if (newTagCounts[tag]) {
            newTagCounts[tag] += 1;
          } else {
            newTagCounts[tag] = 1;
          }
        });
      }
    });

    // Sort if necessary (default is oldest first)
    if (selectedSortDirection === constants.SORT_DIRECTIONS.NEWEST_FIRST) {
      newScreenshots.reverse();
    }

    setScreenshots(newScreenshots);
    setTagCounts(newTagCounts);
  }, [props.screenshots, selectedGameKey, selectedSortDirection, selectedTags]);

  useEffect(() => {
    props.updateDrawerButton(
      <Badge
        invisible={selectedGameKey === 'all'
          && selectedSortDirection === constants.SORT_DIRECTIONS.OLDEST_FIRST
          && selectedTags.length === 0}
      >
        <FilterListIcon />
      </Badge>,
      'Filter/sort screenshots'
    );
  }, [selectedGameKey, selectedSortDirection, selectedTags]);

  useEffect(() => {
    // Restore filter selections from cookies
    const previousRememberSettings = Cookies.get(constants.COOKIES.SCREENSHOTS_REMEMBER);
    if (previousRememberSettings === 'true') {
      setRememberSettings(true);
      const previousGameKey = Cookies.get(constants.COOKIES.SCREENSHOTS_GAME);
      const previousSortDirection = Cookies.get(constants.COOKIES.SCREENSHOTS_SORT);
      const previousTags = Cookies.get(constants.COOKIES.SCREENSHOTS_TAGS);
      if (previousGameKey) setSelectedGameKey(previousGameKey);
      if (previousSortDirection) setSelectedSortDirection(previousSortDirection);
      if (previousTags) setSelectedTags(previousTags.split(','));
    }
  }, []);

  // Tell the Masonry component that it will need to recalculate its cell positions. (Do this before re-rendering.)
  const resetMasonry = () => {
    cellMeasurerCache.clearAll();
    cellPositioner.reset({
      columnCount,
      columnWidth,
      spacer: 0,
    });
    masonry.current && masonry.current.clearCellPositions();
  };

  useEffect(() => {
    // Show a badge when filter settings are other than the defaults
    props.updateDrawerButton(
      <Badge
        color='secondary'
        variant='dot'
        invisible={selectedGameKey === 'all'
          && selectedSortDirection === constants.SORT_DIRECTIONS.OLDEST_FIRST
          && selectedTags.length === 0}
      >
        <FilterListIcon />
      </Badge>,
      'Filter/sort screenshots'
    );
  }, [selectedGameKey, selectedSortDirection, selectedTags]);

  const toggleLightbox = (newShowLightbox, newSelectedIndex) => {
    if (newShowLightbox !== showLightbox || newSelectedIndex !== selectedIndex) {
      setShowLightbox(newShowLightbox);
      setSelectedIndex(newSelectedIndex);
    }
  };

  const cellRenderer = ({index, key, parent, style}) => {
    const screenshot = screenshots[index];

    // TODO Add caption and border, like Masonry demo?
    return (
      <CellMeasurer cache={cellMeasurerCache} index={index} key={key} parent={parent}>
        <div style={style}>
          <img
            className='the-ScreenshotViewer-CellImage'
            alt=''
            title={screenshot.caption}
            src={columnCount > 1 ? screenshot.source.thumbnail : screenshot.source.regular}
            style={{width: columnWidth, height: Math.floor((columnWidth / screenshot.width) * screenshot.height)}}
            onClick={() => toggleLightbox(true, index)}
          />
        </div>
      </CellMeasurer>
    );
  };

  const masonry = useRef(null);

  const handleResize = () => {
    columnCount = Math.floor(window.innerWidth / TARGET_COLUMN_WIDTH);
    columnWidth = Math.floor(window.innerWidth / columnCount);
    resetMasonry();
  };

  const handleScroll = (scrollTop) => {
    // Show/hide the floating action button
    if (showFab) {
      if (scrollTop < window.innerHeight) {
        setShowFab(false);
      }
    } else {
      if (scrollTop > window.innerHeight) {
        setShowFab(true);
      }
    }
  };

  const handleGameSelect = (gameKey) => {
    setSelectedGameKey(gameKey);
    Cookies.set(constants.COOKIES.SCREENSHOTS_GAME, gameKey, {expires: 365, sameSite: 'strict'});
    setSelectedTags([]);
    Cookies.set(constants.COOKIES.SCREENSHOTS_TAGS, '', {expires: 365, sameSite: 'strict'});
  };

  const handleSortSelect = (sortDirection) => {
    setSelectedSortDirection(sortDirection);
    Cookies.set(constants.COOKIES.SCREENSHOTS_SORT, sortDirection, {expires: 365, sameSite: 'strict'});
  };

  const createTagSelectHandler = (tagName) => (event) => {
    const newSelectedTags = [...selectedTags];
    if (event.target.checked) {
      newSelectedTags.push(tagName);
    } else {
      newSelectedTags.splice(selectedTags.indexOf(tagName), 1);
    }
    setSelectedTags(newSelectedTags);
    Cookies.set(constants.COOKIES.SCREENSHOTS_TAGS, newSelectedTags.join(), {expires: 365, sameSite: 'strict'});
  };

  const toggleRememberSettings = (shouldRemember) => {
    if (typeof shouldRemember !== 'boolean') {
      shouldRemember = !rememberSettings;
    }
    setRememberSettings(shouldRemember);
    Cookies.set(constants.COOKIES.SCREENSHOTS_REMEMBER, shouldRemember, {expires: 365, sameSite: 'strict'});
  };

  const resetToDefaults = () => {
    handleGameSelect('all');
    handleSortSelect(constants.SORT_DIRECTIONS.OLDEST_FIRST);
  };

  return (<>
    <DrawerMenu
      title='Filter/Sort Screenshots'
      showDrawer={props.showDrawer}
      toggleDrawer={props.toggleDrawer}
    >
      {/* Game options */}
      {props.games && props.screenshotCounts &&
        <DrawerMenuInputGroup
          type='radio'
          label='Game'
          selectedValue={selectedGameKey}
          setSelectedValue={handleGameSelect}
          allOption='true'
          allTitle='All the games!'
          allCount={Object.keys(props.screenshotCounts).reduce((sum, key) => sum + props.screenshotCounts[key], 0)}
          values={Object.keys(props.screenshotCounts).sort()}
          labels={Object.keys(props.screenshotCounts).sort().map(gameKey => props.games[gameKey].short_name)}
          titles={Object.keys(props.screenshotCounts).sort().map(gameKey => props.games[gameKey].long_name)}
          counts={Object.keys(props.screenshotCounts).sort().map(gameKey => props.screenshotCounts[gameKey])}
        />
      }

      {/* Sort options */}
      {selectedSortDirection &&
        <DrawerMenuInputGroup
          type='radio'
          label='Sort'
          selectedValue={selectedSortDirection}
          setSelectedValue={handleSortSelect}
          values={[constants.SORT_DIRECTIONS.OLDEST_FIRST, constants.SORT_DIRECTIONS.NEWEST_FIRST]}
          labels={['Oldest first', 'Newest first']}
          titles={['Oldies first', 'Newbies first']}
        />
      }

      {/* Tag options */}
      {selectedGameKey && props.screenshotTags && props.screenshotTags[selectedGameKey] &&
        <DrawerMenuInputGroup
          type='checkbox'
          label='Tags'
          selectedValues={selectedTags}
          createSelectHandler={createTagSelectHandler}
          values={Array.from(props.screenshotTags[selectedGameKey]).sort()}
          counts={Array.from(props.screenshotTags[selectedGameKey]).sort().map(tag => tagCounts[tag] || 0)}
        />
      }

      <Divider />

      {/* Remember switch */}
      <FormControlLabel control={
        <Switch checked={rememberSettings} onChange={toggleRememberSettings} />
      } label='Remember settings' />

      {/* Reset button */}
      <Button variant='outlined' color='secondary' onClick={resetToDefaults}>Reset to defaults</Button>
    </DrawerMenu>

    {screenshots &&
      <WindowScroller>
        {({height, scrollTop}) => {
          handleScroll(scrollTop);
          return (
            <AutoSizer disableHeight onResize={handleResize}>
              {({width}) => (
                <Masonry
                  ref={masonry}
                  width={width}
                  height={height}
                  autoHeight={true}
                  overscanByPixels={600}
                  scrollTop={scrollTop}
                  cellCount={screenshots.length}
                  cellMeasurerCache={cellMeasurerCache}
                  cellPositioner={cellPositioner}
                  cellRenderer={cellRenderer}
                />
              )}
            </AutoSizer>
          );
        }}
      </WindowScroller>
    }

    <Zoom in={showFab} timeout={225} unmountOnExit>
      <Fab
        color='primary'
        style={{margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed'}}
        onClick={window.scrollTo.bind(null, 0, 0)}
        title='Scroll to top'
      >
        <UpIcon />
      </Fab>
    </Zoom>

    {showLightbox && screenshots && screenshots[selectedIndex] && screenshots[selectedIndex].source && (
      <Lightbox
        mainSrc={screenshots[selectedIndex].source.regular}
        nextSrc={screenshots[selectedIndex + 1] && screenshots[selectedIndex + 1].source.regular}
        prevSrc={screenshots[selectedIndex - 1] && screenshots[selectedIndex - 1].source.regular}
        mainSrcThumbnail={screenshots[selectedIndex].source.thumbnail}
        nextSrcThumbnail={screenshots[selectedIndex + 1] && screenshots[selectedIndex + 1].source.thumbnail}
        prevSrcThumbnail={screenshots[selectedIndex - 1] && screenshots[selectedIndex - 1].source.thumbnail}
        imageCaption={screenshots[selectedIndex].caption}
        onCloseRequest={() => toggleLightbox(false)}
        onMovePrevRequest={() => setSelectedIndex(selectedIndex - 1)}
        onMoveNextRequest={() => setSelectedIndex(selectedIndex + 1)}
      />
    )}
  </>);
}

export default ScreenshotViewer;
