import {React, useState} from 'react';
import {Route} from 'react-router-dom';

import constants from '../constants.js';

import MomentList from './MomentList';
import Moment from './Moment';

function MomentViewer(props) {
  return (<>
    <Route exact path={constants.PAGE_PATHS.MOMENTS}>
      {props.moments && <MomentList {...props} />}
    </Route>

    <Route exact path={constants.PAGE_PATHS.MOMENTS + '/:id'}>
      {props.moments && <Moment moments={props.moments} updateDrawerButton={props.updateDrawerButton} />}
    </Route>
  </>);
}

export default MomentViewer;
