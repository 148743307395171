import {React} from 'react';
import {FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Checkbox} from '@material-ui/core';

import './DrawerMenuInputGroup.css';

function DrawerMenuInputGroup(props) {
  const labels = props.labels || props.values;
  const titles = props.titles || props.labels || props.values;

  return (<>
    <FormControl className='the-DrawerMenuInputGroup-FormControl' color='secondary' component='fieldset'>
      <FormLabel component='legend'>{props.label + ':'}</FormLabel>
      {props.type == 'radio' &&
        <RadioGroup className='the-DrawerMenuInputGroup-RadioGroup'
          value={props.selectedValue} onChange={event => props.setSelectedValue(event.target.value)}
        >
          {props.allOption &&
            <FormControlLabel value='all' control={<Radio className='the-DrawerMenuInputGroup-RadioButton' />} key='all'
              label={'All (' + props.allCount + ')'}
              title={props.allTitle}
            />
          }
          {props.values.map((value, index) => (
            <FormControlLabel value={value} key={value}
              label={labels[index] + (props.counts ? ' (' + props.counts[index] + ')' : '')}
              title={titles[index]}
              control={<Radio className='the-DrawerMenuInputGroup-RadioButton' />}
            />
          ))}
        </RadioGroup>
      }
      {props.type == 'checkbox' &&
        <FormGroup className='the-DrawerMenuInputGroup-CheckboxGroup'>
          {props.values.map((value, index) => (
            <FormControlLabel key={value} title={titles[index]}
              label={labels[index] + (props.counts ? ' (' + props.counts[index] + ')' : '')}
              control={
                <Checkbox className='the-DrawerMenuInputGroup-Checkbox'
                  value={value} checked={props.selectedValues.includes(value)}
                  onChange={props.createSelectHandler(value)} />
              }
            />
          ))}
        </FormGroup>
      }
    </FormControl>

  </>);
}

export default DrawerMenuInputGroup;
