export default {
  GROUP_KEYS: {
    NIGHTSHADOWS: 'nsls',
    SUPERFUNTIMES: 'yay',
    HORSHAM: 'sham',
  },
  DATA_URLS: {
    GAMES: 'https://db.insectean.com/db/games/',
    SCREENSHOTS: 'https://db.insectean.com/db/screenshots/',
    MOMENTS: 'https://db.insectean.com/db/moments/',
  },
  PAGE_PATHS: {
    HOME: '/',
    SCREENSHOTS: '/screenshots',
    //STORIES: '/stories',
    MOMENTS: '/moments',
  },
  SORT_DIRECTIONS: {
    NEWEST_FIRST: 'newest_first',
    OLDEST_FIRST: 'oldest_first',
  },
  COOKIES: {
    TUTORIAL_SEEN: 'tut',
    SCREENSHOTS_GAME: 'ssg',
    SCREENSHOTS_SORT: 'sss',
    SCREENSHOTS_TAGS: 'sst',
    SCREENSHOTS_REMEMBER: 'ssr',
    MOMENTS_GAME: 'mmg',
    MOMENTS_SORT: 'mms',
    MOMENTS_REMEMBER: 'mmr',
  },
  APPBAR_HEIGHT: 50, // Make sure this matches --appbar-height in App.css
  MOMENT_CARD_WIDTH: 265, // Make sure this just fits the longest moment title and matches MomentViewer.css
};
